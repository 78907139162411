<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
 
    <template #default="{ hide }" v-if="userData!=null&&userData!=undefined">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Publisher
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Publisher Name"
            rules="required"
          >
            <b-form-group
              label="Publisher Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Publisher Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

       

        

          
 

             <!-- Username -->
         
            <b-form-group
              label="Publisher Headline"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.headline"
               
                   placeholder="Publisher Headline"
                trim
              />

              
            </b-form-group>
         

          
         
          <b-media no-body>
      <b-media-aside>
        <b-form-group
              label="Publisher Photo"
              label-for="photo"
            >
        <b-link>
          <b-img
            ref="previewEl" 
            rounded
            :src="userData.image"
            height="100"
            alt=""
            width="100"
          />
          
        </b-link>
      </b-form-group>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          :state="Boolean(file)"
          v-model="file"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="fileSelected"
        />
        <!--/ upload button -->

        <!-- reset -->

        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 2 MB</b-card-text>
      </b-media-body>
    </b-media>

           
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="userData==null|| userData.id==null"
            >
                     Add
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="updatecategory"
            v-if="userData!=null&&userData.id!=null"
            >
                      Update 
            </b-button>

              <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
                  class="mr-2"
                  v-if="userData!=null&& userData.id!=null"
              variant="outline-danger"
              @click="deletecategory"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BCardText,  BMedia,
  BMediaAside,BFormFile,  BCard, BLink,
  BMediaBody,
  BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import DepartmentPicker from '../components/DepartmentPicker.vue'
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,  BCard, 
    BCardText,
    BMedia,
  BMediaAside,BLink,
  BMediaBody,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    DepartmentPicker,
  BImg,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  methods:{
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response);
          //console.log( response.data.url);
          //console.log(  this.userData);
          this.userData.image = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          
        })
        .catch((error) => {
          //console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },
    

    uploadFile(paylod) {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },
    
  
    deletecategory(){
      this.userData.deleted=true;

      this.$store.dispatch('apps-blogpublisher/updateblogpublisher', this.userData)
        .then((result) => {
          //console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    },
    updatecategory(){
     //console.log("sent data");
     //console.log(this.currentCategory);

      this.$store.dispatch('apps-blogpublisher/updateblogpublisher', this.userData)
        .then((result) => {
          //console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    },

    submitForm(){
 
    //console.log(this.userData);
    
       this.$store.dispatch('apps-blogpublisher/createPublisher', this.userData)
        .then((result) => {
          //console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    }
  },
  
  watch: {
     categorydata:   {
        handler:function (n, o)   {
          //console.log('new publisher data: ', n);

                  if (n==null||n==undefined)
                  {
                    this.userData={
                      id:null,
                      title:null,
                      headline:null,
                       image:null
                    }
                  } else
                  { 
                    //console.log('new client: ', n);
                  this.userData = n.value;
                  }
        

        },
        deep: true
    }
    
},
  
  data() {
    return {
      required,
      alphaNum,
      email,
      isLoading: false,
      file: null,
      profileFile: null,
      isUpdated: false,
      countries,
      currentCategory :{}
    }
  },
   inject: ['categorydata'],
  setup(props, { emit }) {


    const refInputEl = ref(null);
    const previewEl = ref(null);


    const blankUserData = {
      id: '',
      title:null,
                      headline:null,
                       image:null
       
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    const onSubmit = () => {
      store.dispatch('apps-blogpublisher/createPublisher', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      refInputEl,
      previewEl,
      inputImageRenderer,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
